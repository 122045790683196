import blogimage1 from "../assets/home/large_webp_element-erde-vertrauen-sicherheit-anna-rech-xenia-bluhm-yogaeasy-header.jpg.webp.png";
import blogimage2 from "../assets/home/large_webp_motiv_thema-lehrerin-yogaeasy-header-.jpg.webp.png";
import blogimage3 from "../assets/home/large_webp_stimme-persoenlichkeit-irina-valentin-alex-xenia-bluhm-yogaeasy-header__1_.jpg.webp.png";

export const blogs = [
  {
    img: blogimage1,
    alt: "Stimmtraining Yoga",
    title:
      "Elevate Your Yoga Practice: Expert Tips, Meditation Techniques, and Wellness Insights",
    link: "/blogs/How-yoga-transforms-body-and-mind",
    linkText: "Read more ›",
  },
  {
    img: blogimage2,
    alt: "Erdung Yoga",
    title:
      "Unlock Holistic Well-being: Essential Yoga Tips and Meditation Guidance for a Balanced Life",
    link: "/blogs/top-5-yogaposes-for-stress-relief",
    linkText: "Read more ›",
  },
  {
    img: blogimage3,
    alt: "Element Erde Yoga",
    title:
      "Transform Your Yoga Journey: Expert Advice, Mindful Meditation, and Wellness Strategies",
    link: "/blogs/How-to-get-started-on-your-journey",
    linkText: "Read more ›",
  },
];
