import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Expandable from "./Expandable.jsx";
import { useState } from "react";

import slider1 from "../assets/home/slider1.png";
import slider2 from "../assets/home/slider2.png";
import slider3 from "../assets/home/slider3.png";

import banner2 from "../assets/home/academy-desktop.jpg.png";

import reviewbackground from "../assets/home/review.png";
import star from "../assets/home/star.png";

import "./home.css";
import { IoSend } from "react-icons/io5";
import {
  MdKeyboardDoubleArrowRight,
  MdOutlineNavigateNext,
} from "react-icons/md";
import Button from "../Modules/Button.jsx";
import Section from "../Modules/Section.jsx";
import Row from "../Modules/Row.jsx";
import { Featuredata } from "../Arrays/Featuredata.jsx";
import { Plans } from "../Arrays/Plan.jsx";
import { IoMdTimer } from "react-icons/io";
import { blogs } from "../Arrays/Blogarray.jsx";
import Modal from "./Modal.jsx";
import { Link } from "react-router-dom";

const Home = () => {
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // Show only one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds for autoplay
  };

  const settings1 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Default: 3 slides
    slidesToScroll: 1, // Scroll one slide at a time
    autoplay: true,
    autoplaySpeed: 3000, // 3 seconds for autoplay
    responsive: [
      {
        breakpoint: 768, // Target screens with width 768px or less
        settings: {
          slidesToShow: 1, // Show only 1 slide
          slidesToScroll: 1, // Scroll one slide at a time
        },
      },
    ],
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState("");

  const openModal = (plan) => {
    setSelectedPlan(plan);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formDataObject = Object.fromEntries(formData.entries());
    console.log("Form Data:", formDataObject);
    // Handle form submission (e.g., send it to a server)
    closeModal();
  };

  return (
    <>
      <Modal isOpen={isModalOpen} closeModal={() => setModalOpen(false)} />
      <section className="h-screen w-full header bg-center bg-no-repeat flex flex-col bg-cover">
        <div className="flex h-full justify-center items-center w-full"></div>
        <div className="flex h-full justify-center items-start w-full flex-col">
          <div className="w-1/2 h-full flex flex-col justify-center items-start px-32 pt-20 mx-4 mt-7 gap-2 max-sm:w-full max-sm:px-7 max-sm:pt-5 max-sm:mt-2 max-md:w-full">
            <h1 className="text-6xl font-bold max-sm:text-4xl">Yog Yatra</h1>
            <h1 className="w-10/12 text-lg">
              Discover the transformative power of yoga with our expert-led
              courses, designed to boost your wellness and awareness through
              mindful practices for body, mind, and soul.
              <Button
                classname=""
                bgcolor="#f5928b"
                textcolor="#3D1818"
                onclick={openModal}
              >
                Enroll now <MdOutlineNavigateNext />
              </Button>
            </h1>
          </div>
        </div>
      </section>

      {/* Section with three images and text */}
      <Section classname="">
        <Row
          classname="flex w-5/6 h-auto py-10 px-5 gap-6 rounded-lg shadow-lg shadow-gray-100 max-md:w-full max-sm:flex-col max-sm:w-11/12"
          bgcolor="#f8f8f6"
        >
          {Featuredata.map((element) => (
            <div className=" w-full h-full flex flex-col justify-center items-center gap-1">
              <img
                alt={element.alt}
                src={element.imgSrc}
                className="h-12 w-12 bg-center bg-contain"
              />
              <h2 className=" text-xl font-semibold darktext text-center">
                {element.title}
              </h2>
              <p className="text-center">{element.description}</p>
            </div>
          ))}
        </Row>
      </Section>

      <Section justifycontent="start" alignitems="center">
        <h2 className="darktext text-center">All level yoga courses</h2>
        <p className="text-center w-10/12">
          Begin your yoga journey with our handcrafted courses and captivating
          yoga videos, asana tutorials, and meditations, offering the perfect
          sequences to deepen your practice and strengthen your soul, mind and
          body.
        </p>

        <Row classname="gap-4 mt-8 w-11/12 max-sm:w-full">
          {Plans.map((element) => (
            <div
              className="w-full h-full rounded-b-lg rounded-t-3xl cremebg shadow-md shadow-gray-200 hover:shadow-xl duration-300 hover:shadow-gray-200"
              onclick={openModal}
            >
              <img src={element.img} className="w-full" />
              <div className="w-full py-4 px-3 flex flex-col gap-2">
                <h2 className="text-xl pb-0 darktext">{element.title}</h2>
                <p className="text-sm">{element.description}</p>
                <div className="flex items-center gap-2">
                  <p className="text-sm flex gap-1 justify-center items-center">
                    <IoMdTimer />
                    {element.duration} |{" "}
                  </p>
                  <p className="text-sm">{element.price}</p>{" "}
                </div>
              </div>
            </div>
          ))}
        </Row>
      </Section>

      <Section classname="cremebg" justifycontent="center" alignitems="center">
        <div className="w-2/3 max-sm:w-full">
          <h2 className="darktext text-center max-sm:text-2xl">
            Earn a diploma or certificate in yoga from our seasoned experts, and
            become part of our movement
          </h2>
          <p className="text-center">
            Join our certified yoga programs, guided by experienced instructors,
            and gain comprehensive knowledge in yoga. Achieve recognized
            credentials, empowering you to inspire others and contribute to the
            global wellness movement.
          </p>
        </div>
      </Section>

      <div className="banner1">
        <div className="w-full h-auto flex flex-col max-sm:h-48"></div>
        <div className="w-full h-full flex flex-col justify-center items-center">
          <h2 className="darktext font-semibold text-3xl max-sm:text-2xl p-0">
            Join our community now
          </h2>
          <p className="text-sm">And become a part of this healthy movement</p>
          <Button onclick={openModal}>
            Enroll now <IoSend />
          </Button>
        </div>
      </div>
      <section className="teachers-section">
        <p className="section-description">
          Our highly skilled yoga instructors bring years of experience and
          passion to every class. Dedicated to your growth, they provide
          personalized guidance, helping you achieve physical, mental, and
          spiritual balance through yoga.
        </p>
        <section className="sliding">
          <Slider {...settings1}>
            <div className="video-slide">
              <iframe
                width="90%"
                height="400"
                src="https://www.youtube.com/embed/FD9SgsEsTms"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            <div className="video-slide">
              <div>
                <iframe
                  width="90%"
                  height="400"
                  src="https://www.youtube.com/embed/Ayb9whkmbD8"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div className="video-slide">
              <div>
                <iframe
                  width="90%"
                  height="400"
                  src="https://www.youtube.com/embed/wK4L2_ajgcg"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </Slider>
        </section>
      </section>

      {/* Slider section */}
      <section className="sliding">
        <Slider {...settings}>
          <div>
            <img src={slider1} alt="Slide 1" />
          </div>
          <div>
            <img src={slider2} alt="Slide 2" />
          </div>
          <div>
            <img src={slider3} alt="Slide 3" />
          </div>
        </Slider>
      </section>

      <Section>
        <Button onclick={openModal}>For best offers on our courses</Button>
        <h2>Explore our blog</h2>
        <p className="w-3/4 text-center">
          Explore expert insights, practical yoga tips, effective meditation
          techniques, and holistic wellness advice through our informative
          blogs, crafted to inspire and elevate your yoga practice and personal
          growth journey.
        </p>
        <Row classname="w-10/12 h-full mt-10 gap-4 justify-center items-center">
          {blogs.map((element) => (
            <div className="w-full h-full flex flex-col">
              <img src={element.img} alt={element.alt} />
              <div className="w-fullv gap-2 h-full flex flex-col justify-center items-center themebg py-3 rounded-b-2xl px-4">
                <p className="text-white text-center text-sm">
                  {element.title}
                </p>
                <Link to={element.link}>
                  <Button
                    onclick={openModal}
                    classname="py-1"
                    bgcolor="transparent"
                    textcolor="#3D1818"
                    animationbgcolor="#fff"
                    animationtextcolor="#3d1818"
                  >
                    Read more <MdKeyboardDoubleArrowRight />
                  </Button>
                </Link>
              </div>
            </div>
          ))}
        </Row>
      </Section>

      <section className="live-classes-section">
        <div className="live-classes-content">
          <h2 className="darktext">
            Find connections in live classes and workshops
          </h2>
          <p>
            Immerse yourself in a rejuvenating journey of self-discovery through
            yoga. Our expert-led workshop focuses on holistic well-being,
            blending mindfulness, physical fitness, and spiritual growth.
          </p>
        </div>
      </section>

      <div className="banner2">
        <img src={banner2} alt="Natarajasana Flow" />
      </div>

      <section className="reviews">
        {/* First Review */}
        <div
          className="review-box"
          style={{ backgroundImage: `url(${reviewbackground})` }}
        >
          <div className="stars flex flex-row">
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
          </div>

          <h3 className="review-title py-1 font-semibold">Aarav Sharma</h3>
          <p className="review-text">
            The courses have significantly improved my flexibility and overall
            well-being. The structured lessons and expert guidance made the
            experience truly enriching. Highly recommended for anyone looking to
            advance their practice!
          </p>
        </div>

        {/* Second Review */}
        <div
          className="review-box"
          style={{ backgroundImage: `url(${reviewbackground})` }}
        >
          <div className="stars flex flex-row">
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
          </div>
          <h3 className="review-title py-1 font-semibold">Riya Kapoor</h3>
          <p className="review-text">
            The instructors were fantastic, and the sessions were easy to
            follow, even for beginners. I feel more confident in my yoga
            practice now. A great experience overall!
          </p>
        </div>

        {/* Third Review */}
        <div
          className="review-box"
          style={{ backgroundImage: `url(${reviewbackground})` }}
        >
          <div className="stars flex flex-row">
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
            <img src={star} alt="star" />
          </div>

          <h3 className="review-title py-1 font-semibold">Siddharth Verma</h3>
          <p className="review-text">
            The meditation practices offered profound mental peace and clarity.
            It’s been a transformative journey, and I feel more balanced and
            centered. Excellent course for both body and mind.
          </p>
        </div>
      </section>

      <section>
        <div className="mobile-reviews">
          <Slider {...settings}>
            <div
              className="review-box"
              style={{ backgroundImage: `url(${reviewbackground})` }}
            >
              <div className="stars">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>

              <h3 className="review-title">PERFEKT!</h3>
              <p className="review-text">
                Es ist ein besonderes Geschenk, die Lebensqualität mit YogaEasy
                zu erhöhen.
              </p>
              <p className="review-author">Erika via ausgezeichnet.org</p>
            </div>
            <div
              className="review-box"
              style={{ backgroundImage: `url(${reviewbackground})` }}
            >
              <div className="stars">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>

              <h3 className="review-title">PERFEKT!</h3>
              <p className="review-text">
                Es ist ein besonderes Geschenk, die Lebensqualität mit YogaEasy
                zu erhöhen.
              </p>
              <p className="review-author">Erika via ausgezeichnet.org</p>
            </div>
            <div
              className="review-box"
              style={{ backgroundImage: `url(${reviewbackground})` }}
            >
              <div className="stars">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>

              <h3 className="review-title">PERFEKT!</h3>
              <p className="review-text">
                Es ist ein besonderes Geschenk, die Lebensqualität mit YogaEasy
                zu erhöhen.
              </p>
              <p className="review-author">Erika via ausgezeichnet.org</p>
            </div>
            <div
              className="review-box"
              style={{ backgroundImage: `url(${reviewbackground})` }}
            >
              <div className="stars">
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
                <img src={star} alt="star" />
              </div>

              <h3 className="review-title">PERFEKT!</h3>
              <p className="review-text">
                Es ist ein besonderes Geschenk, die Lebensqualität mit YogaEasy
                zu erhöhen.
              </p>
              <p className="review-author">Erika via ausgezeichnet.org</p>
            </div>
          </Slider>
        </div>
      </section>

      <section className="faq-section">
        <h1 className="pb-4">Frequently asked questions</h1>
        <Expandable title="What courses and certifications do you offer in yoga?">
          <p className="text-sm">
            We offer a variety of programs, including diploma and certification
            courses in yoga. These courses are designed for beginners,
            intermediate learners, and advanced practitioners looking to deepen
            their knowledge and skills in yoga.
          </p>
        </Expandable>

        <Expandable title="What is the difference between a diploma and a certificate in yoga?">
          <p className="text-sm">
            A diploma in yoga is typically more comprehensive, covering in-depth
            theory, practice, and teaching methodologies over a longer duration.
            A certificate course, on the other hand, focuses on specific aspects
            of yoga practice and is shorter in duration, making it suitable for
            those looking for a quick introduction or skill enhancement.
          </p>
        </Expandable>

        <Expandable title="How do your courses help in raising awareness about yoga?">
          <p className="text-sm">
            Our courses not only teach yoga techniques but also emphasize the
            holistic benefits of yoga for physical, mental, and emotional
            well-being. We aim to raise awareness about the transformative power
            of yoga, helping students incorporate it into their daily lives and
            spread the knowledge to others.
          </p>
        </Expandable>

        <Expandable title="Are your courses suitable for complete beginners?">
          <p>
            Yes, we offer beginner-friendly courses that provide a strong
            foundation in yoga practice and philosophy. Whether you're new to
            yoga or looking to advance your practice, our programs are tailored
            to meet the needs of all levels.
          </p>
        </Expandable>
      </section>
    </>
  );
};

export default Home;
